<template>
  <b-row>
    <b-col md="12">

      <!-- Login v1 -->
      <b-overlay
        :show="loader"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-lg
        rounded="sm"
      >

        <b-card
          border-variant="info"
          header="Modifier une villa"
          header-bg-variant="light-info"
          header-text-variant="white"
          header-tag="header"
          header-class="h2 "
          class="mb-0 mb-5"
        >
          <validation-observer
            #default="{ invalid }"
            ref="villaRules"
          >

            <b-form @submit.prevent="handleVillaSubmit">
              <b-row class="justify-content-between mt-2">
                <b-col md="12">
                  <b-card title="Géolocatisation">
                    <l-map
                      ref="map"
                      :zoom="zoom"
                      :center="userLocation|| position"
                      :options="mapOptions"
                      style="height: 255px"
                      @click="onMapClick"
                    >
                      <l-geosearch :options="geoSearchOptions" />

                      <l-tile-layer
                        :name="tileProvider.name"
                        :visible="tileProvider.visible"
                        :url="tileProvider.url"
                        :attribution="tileProvider.attribution"
                        layer-type="base"
                      />
                      <l-circle
                        v-if="position.lat && position.lng"
                        :lat-lng.sync="userLocation"
                        :radius="circle.radius"
                        :color="circle.color"
                      />
                      <l-control-attribution
                        :position="attributionPosition"
                        :prefix="attributionPrefix"
                      />

                      <l-marker
                        v-if="position.lat && position.lng"
                        visible
                        :icon="iconT"
                        draggable
                        :lat-lng.sync="position"
                        @dragstart="dragging = true"
                        @dragend="dragging = false"
                      >
                        <l-tooltip
                          :content="tooltipContent"
                          :options="{ permanent: false }"
                        />
                      </l-marker>
                    </l-map>
                  </b-card>
                </b-col>
                <b-col md="12">
                  <b-row>
                    <b-col
                      v-for="item in propriete.propriete_images"
                      :key="item.id"
                      md="2"
                    >
                      <div class="card">
                        <div class="card-body">
                          <img
                            id="brandLogo"
                            :src="item.image_url"
                            class="img-fluid"
                            :alt="item.image"
                          >
                          <a
                            class="card-link float-right text-danger"
                            title="Supprimer"
                          >
                            <i
                              class="fa fa-fw fa-lg fa-trash"
                              @click.prevent="deleteImage(item.id)"
                            />
                          </a>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Nom de la villa"
                    label-for="name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="nom de la villa"
                      rules="required"
                    >
                      <b-form-input
                        id="name"
                        v-model="villaForm.name"
                        size="sm"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Nom de la villa"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  v-if="villaForm.is_multiple_proprietaire==='true'"
                  md="6"
                >
                  <b-form-group
                    label="Sélectionner le propriétaire"
                    label-for="proprietaire_actuel"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="propriétaire"
                      rules="required"
                    >
                      <v-select
                        id="proprietaire_actuel"
                        v-model="villaForm.proprietaire_actuel"
                        label="name"
                        size="sm"
                        :state="errors.length > 0 ? false : null"
                        :options="users"
                        placeholder="Sélectionner le propriétaire"
                        :reduce="(users) => users.id"
                        class="select-size-sm"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Type de résidence"
                    label-for="residence"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="residence"
                      rules="required"
                    >
                      <div class="d-flex">

                        <b-form-radio
                          v-model="villaForm.residence"
                          plain
                          name="residence"
                          value="Privée"
                          class="mr-4"
                        >
                          Privée
                        </b-form-radio>
                        <b-form-radio
                          v-model="villaForm.residence"
                          plain
                          name="residence"
                          value="En location"
                        >
                          En location
                        </b-form-radio>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="villaForm.residence === 'En location'"
                  md="6"
                >
                  <b-form-group
                    label="Loyer"
                    label-for="loyer"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="loyer"
                      rules="required"
                    >
                      <b-form-input
                        id="loyer"
                        v-model="villaForm.loyer"
                        size="sm"
                        type="number"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Loyer"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Adresse"
                    label-for="adresse"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="adresse"
                      rules="required"
                    >
                      <b-form-input
                        id="adresse"
                        v-model="villaForm.adresse"
                        size="sm"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Adresse"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Superficie"
                    label-for="superficie"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="superficie"
                      rules="required"
                    >
                      <b-form-input
                        id="superficie"
                        v-model="villaForm.superficie"
                        size="sm"
                        type="number"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Superficie"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Sélectionner les images de la villa"
                    label-for="fichier"
                    label-size="sm"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="image"
                    >
                      <b-form-file
                        id="fichier"
                        ref="fileInput"
                        v-model="villaForm.fileName"
                        accept="image/*"
                        size="sm"
                        multiple
                        placeholder="Choisir un fichier"
                        @change="handleMultipleFilesSelected"
                      >
                        <template
                          slot="file-name"
                          slot-scope="{ names }"
                        >
                          <b-badge variant="primary">
                            {{ names[0] }}
                          </b-badge>
                          <b-badge
                            v-if="names.length > 1"
                            variant="dark"
                            class="ml-1"
                          >
                            + {{ names.length - 1 }} Fichiers
                          </b-badge>
                        </template>
                      </b-form-file>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <b-button
                        v-if="uploadProcess"
                        variant="success"
                        disabled
                        block
                        size="sm"
                        class="mt-1"
                      >
                        <b-spinner small />
                        Chargement...
                      </b-button>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <!-- button -->
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="danger"
                    size="sm"
                    class="my-2 float-right ml-2"
                    :to="{ name: 'espace-coproprietaire.proprietes', replace: true }"
                  >
                    Annuler
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="info"
                    size="sm"
                    :disabled="invalid || proprieteProcess"
                    type="submit"
                    class="my-2 float-right"
                  >
                    Modifier
                    <b-spinner
                      v-if="proprieteProcess"
                      variant="light"
                      class="ml-1"
                      type="grow"
                      small
                    />
                  </b-button>
                </b-col>

              </b-row>
            </b-form>
          </validation-observer>

        </b-card>

      </b-overlay>

    </b-col>
  </b-row>
</template>

<script>

import {
  ref, reactive, onMounted, computed, watch,
} from '@vue/composition-api'
import {
  LMap, LTileLayer, LMarker, LControlAttribution, LCircle, LTooltip,
} from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
import vSelect from 'vue-select'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, image, mimes,
} from '@validations'

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BOverlay,
  BBadge,
  BCard,
  BFormFile,
  BForm,
  BSpinner,
  BButton,
  VBModal,
  BFormRadio,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-cycle
import useImageUpload from '@/composables/upload/useImageUpload'
// eslint-disable-next-line import/no-cycle
import store from '@/store'

// eslint-disable-next-line import/no-cycle
import useProprietes from '@/composables/proprieteService'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
// eslint-disable-next-line import/no-cycle
import useGestionCoproprietes from '@/composables/gestionCoproprieteService'
// eslint-disable-next-line import/no-cycle
import useProprieteImages from '@/composables/proprieteImageService'
import { Icon, icon } from 'leaflet'
import { OpenStreetMapProvider } from 'leaflet-geosearch'
import LGeosearch from 'vue2-leaflet-geosearch'
import Swal from 'sweetalert2'
// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  // eslint-disable-next-line global-require
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  // eslint-disable-next-line global-require
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  // eslint-disable-next-line global-require
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BOverlay,

    BCard,
    BRow,
    BCol,
    BForm,
    BButton,
    BBadge,
    BSpinner,

    LMap,
    LTileLayer,
    LControlAttribution,
    LCircle,
    LGeosearch,
    LMarker,
    LTooltip,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    BFormRadio,
    // eslint-disable-next-line vue/no-unused-components

  },

  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  setup() {
    const {
      getCoproprieteCoproprietaires, coproprietaires,
    } = useGestionCoproprietes()
    const { deleteProprieteImage, storeFilesInPropriete } = useProprieteImages()
    const user = JSON.parse(store.state.authStore.user)
    const {
      filesName, uploadFileMultiple, handleMultipleFilesSelected, filesObject, uploadProcess,
    } = useImageUpload()
    const {
      updatePropriete, proprieteProcess, proprieteSuccess, getProprieteById, propriete,
    } = useProprietes()
    const fileInput = ref('')
    const position = ref({})

    const villaForm = reactive({
      id: null,
      latitude: '',
      longitude: '',
      type_propriete_id: 2,
      copropriete_id: user.current_coownership,
      name: '',
      superficie: '',
      proprietaire_actuel: '',
      residence: null,
      loyer: null,
      adresse: '',
      fileName: [],
      temporay_url: null,
    })
    const oldImage = ref(null)
    const villaRules = ref('')
    const loader = ref(false)
    const users = ref([])

    // Reset Form
    const resetVillaForm = () => {
      villaForm.id = null
      villaForm.latitude = ''
      villaForm.longitude = ''
      villaForm.name = ''
      villaForm.superficie = ''
      villaForm.loyer = ''
      villaForm.proprietaire_actuel = ''
      villaForm.adresse = ''
      villaForm.image = ''
      villaForm.fileName = []
      villaForm.temporay_url = null
      oldImage.value = null
    }

    const uploadMultipleFiles = async dataFiles => {
      const data = new FormData()
      // eslint-disable-next-line no-restricted-syntax, no-shadow
      for (const file of dataFiles) {
        data.append('fichiers[]', file)
      }
      await uploadFileMultiple(data)
      await storeFilesInPropriete({ propriete_id: propriete.value.id, fichiers: filesName.value })
      await getProprieteById(router.currentRoute.params.id)
      fileInput.value.reset()
      filesObject.value = []
    }
    watch(() => (filesObject.value), async value => {
      if (filesObject.value.length > 0) await uploadMultipleFiles(value)
    })
    const handleVillaSubmit = () => {
      villaRules.value.validate().then(async success => {
        if (success) {
          const { lat, lng } = position.value
          villaForm.latitude = lat
          villaForm.longitude = lng
          await updatePropriete({ ...villaForm })
          if (proprieteSuccess.value) {
            resetVillaForm()
            fileInput.value.reset()
            router.push({ name: 'espace-coproprietaire.proprietes', replace: true })
          }
        }
      })
    }
    const deleteImage = async id => {
      Swal.fire({
        title: 'Suppression',
        text: 'Etes-vous sûr de vouloir supprimer cette image ?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-sm',
          cancelButton: 'btn btn-danger btn-sm ml-1',
        },
        cancelButtonText: 'Annuler',
        confirmButtonText: 'Supprimer',
      }).then(async result => {
        if (result.isConfirmed) {
          if (propriete.value.propriete_images.length === 1) {
            Swal.fire({

              title: 'Oups! Erreur',
              text: 'Vous ne pouvez pas supprimer toutes les images.',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-danger btn-sm',
              },
              confirmButtonText: 'Ok',

            })
            return
          }
          await deleteProprieteImage(id)
          await getProprieteById(router.currentRoute.params.id)

          Swal.fire({

            title: 'Supprimée!',
            text: 'Image supprimée avec succès.',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-success btn-sm',
            },
            confirmButtonText: 'Ok',

          })
        }
      })
    }

    const zoom = ref(10)
    const loading = ref(false)
    const attributionPrefix = ref('Logis Connect')
    const attributionPosition = ref('bottomright')
    const userLocation = reactive({
      lat: null,
      lng: null,
    })
    const map = ref('')
    const mapOptions = reactive({
      zoomControl: true,
      attributionControl: false,
      zoomSnap: true,
    })
    const geoSearchOptions = reactive({
      provider: new OpenStreetMapProvider(),
      showMarker: false,
      autoClose: true,
    })
    const circle = reactive({
      radius: 300,
      color: '#EA5455',
    })
    const tileProvider = ref({
      name: 'OpenStreetMap',
      visible: true,
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    })
    const iconT = icon({
      // eslint-disable-next-line global-require
      iconUrl: require('@/assets/images/location.gif'),
      iconSize: [40, 80],
      iconAnchor: [16, 37],
    })

    const dragging = ref(false)
    const address = ref(null)

    const getUserPosition = async () => {
      if (navigator.geolocation) {
        // get GPS position
        navigator.geolocation.getCurrentPosition(pos => {
          // set the user location
          userLocation.lat = pos.coords.latitude
          userLocation.lng = pos.coords.longitude
        })
      }
    }
    const getAddress = async () => {
      loading.value = true
      address.value = 'Unresolved address'
      try {
        const { lat, lng } = position.value
        const result = await fetch(
          `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`,
        )
        if (result.status === 200) {
          const body = await result.json()
          address.value = body.display_name
        }
      } catch (e) {
        console.error('Reverse Geocode Error->', e)
      }
      loading.value = false
    }
    const onMapClick = value => {
      // place the marker on the clicked spot
      position.value = value.latlng
    }
    const onSearch = value => {
      const loc = value.location
      position.value = { lat: loc.y, lng: loc.x }
    }

    // eslint-disable-next-line no-nested-ternary
    const tooltipContent = computed(() => (dragging.value ? '...' : loading.value ? 'Loading...' : address.value !== null ? `<strong>${address.value.replace(
      ',', '<br/>',
    )}</strong> <hr/><strong>lat:</strong> ${position.value.lat
    }<br/> <strong>lng:</strong> ${position.value.lng}` : ''))

    watch(() => (position.value), async value => {
      position.value = value
      await getAddress()
    })
    onMounted(async () => {
      await getCoproprieteCoproprietaires(user.current_coownership)
      await getProprieteById(router.currentRoute.params.id)
      position.value = { lat: propriete.value.latitude, lng: propriete.value.longitude }
      villaForm.id = propriete.value.id
      villaForm.latitude = propriete.value.latitude
      villaForm.longitude = propriete.value.longitude
      villaForm.name = propriete.value.name
      villaForm.latitude = propriete.value.latitude
      villaForm.longitude = propriete.value.longitude
      villaForm.superficie = propriete.value.superficie
      villaForm.loyer = propriete.value.loyer
      villaForm.proprietaire_actuel = propriete.value.proprietaire_actuel.id
      villaForm.adresse = propriete.value.adresse
      villaForm.residence = propriete.value.residence

      await getUserPosition()
      users.value = coproprietaires.value.users
      // eslint-disable-next-line no-prototype-builtins
      map.value.mapObject.on('geosearch/showlocation', onSearch)
      //   console.clear()
    })
    return {
      required,
      image,
      dragging,
      mimes,
      map,
      zoom,
      iconT,
      geoSearchOptions,
      attributionPrefix,
      attributionPosition,
      mapOptions,
      userLocation,
      circle,
      tileProvider,
      address,
      getAddress,
      onMapClick,
      onSearch,
      propriete,
      tooltipContent,
      position,
      villaForm,
      uploadProcess,
      loader,
      handleMultipleFilesSelected,
      handleVillaSubmit,
      proprieteProcess,
      fileInput,
      users,
      villaRules,
      deleteImage,
    }
  },

}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/map-leaflet.scss';

.vue2leaflet-map {
  &.leaflet-container {
    height: 350px;
  }
}

.leaflet-control-geosearch form input {
  min-width: 311px;
}
</style>
