/* eslint-disable no-shadow */
/* eslint-disable prefer-destructuring */
import { ref, watch } from '@vue/composition-api'
import axiosClient from '@/helpers/axios'
import useAlertNotification from '@/composables/notification/useAlert'

export default function useImageUpload() {
  const imageFile = ref(null)
  const pdfFile = ref(null)
  const imageUrl = ref(null)
  const fileSize = ref(null)
  const errors = ref([])
  const fileName = ref(null)
  const fileNameEtat = ref(null)
  const uploadProcess = ref(false)
  const { errorToast, successToast } = useAlertNotification()
  const filesObject = ref([])
  const filesName = ref([])

  const uploadFile = async data => {
    try {
      const response = await axiosClient.post('/upload-file', data)
      fileName.value = response.data.image
      // successToast('Fichier chargé avec succès')
    } catch (error) {
      errors.value = ''
      errors.value = error.response.data.errors
      const uploadFileErrors = error.response.data.errors
      Object.keys(uploadFileErrors).forEach(key => {
        errorToast(uploadFileErrors[key][0])
      })
    }
  }
  const uploadPdfFile = async data => {
    try {
      const response = await axiosClient.post('/upload-pdf-file', data)
      fileName.value = response.data.pdf_file
      successToast('Fichier chargé avec succès')
    } catch (error) {
      errors.value = ''
      errors.value = error.response.data.errors
      const uploadFileErrors = error.response.data.errors
      Object.keys(uploadFileErrors).forEach(key => {
        errorToast(uploadFileErrors[key][0])
      })
    }
  }
  const uploadPdfFileEtatDesLieux = async data => {
    try {
      const response = await axiosClient.post('/upload-pdf-file', data)
      fileNameEtat.value = response.data.pdf_file
      successToast('Fichier chargé avec succès')
    } catch (error) {
      errors.value = ''
      errors.value = error.response.data.errors
      const uploadFileErrors = error.response.data.errors
      Object.keys(uploadFileErrors).forEach(key => {
        errorToast(uploadFileErrors[key][0])
      })
    }
  }
  const uploadFileMultiple = async data => {
    try {
      const response = await axiosClient.post('/upload-multiple-files', data)
      filesName.value = response.data.files
    } catch (error) {
      errors.value = ''
      errors.value = error.response.data.errors
      const uploadFileErrors = error.response.data.errors
      Object.keys(uploadFileErrors).forEach(key => {
        errorToast(uploadFileErrors[key][0])
      })
    }
  }
  const deleteFile = async (fileName, folder) => {
    await axiosClient.delete(`/delete-file/${fileName}/${folder}`)
      .then()
      .catch(error => {
        console.log(error)
      })
  }

  const handleImageSelected = event => {
    uploadProcess.value = true
    if (event.target.files.length === 0) {
      imageFile.value = null
      imageUrl.value = null
      uploadProcess.value = false
      return
    }
    imageFile.value = event.target.files[0]
    fileSize.value = event.target.files[0].size

    setTimeout(() => {
      uploadProcess.value = false
    }, 1000)
  }
  const handlePdfSelected = event => {
    uploadProcess.value = true
    if (event.target.files.length === 0) {
      imageFile.value = null
      imageUrl.value = null
      uploadProcess.value = false
      return
    }
    imageFile.value = event.target.files[0]
    fileSize.value = event.target.files[0].size
    setTimeout(() => {
      uploadProcess.value = false
    }, 1000)
  }
  const handlePiecePdfSelected = event => {
    uploadProcess.value = true
    if (event.target.files.length === 0) {
      pdfFile.value = null
      imageUrl.value = null
      uploadProcess.value = false
      return
    }
    pdfFile.value = event.target.files[0]
    fileSize.value = event.target.files[0].size
    setTimeout(() => {
      uploadProcess.value = false
    }, 1000)
  }
  const handleMultipleFilesSelected = event => {
    uploadProcess.value = true
    if (event.target.files.length === 0) {
      filesObject.value = []
      return
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const file of event.target.files) {
      try {
        filesObject.value.push(file)
        // eslint-disable-next-line no-empty
      } catch (error) {}
    }
    setTimeout(() => {
      uploadProcess.value = false
    }, 1000)
  }

  watch(imageFile, imageFile => {
    if (!(imageFile instanceof File)) {
      return
    }

    const fileReader = new FileReader()

    fileReader.readAsDataURL(imageFile)

    fileReader.addEventListener('load', () => {
      imageUrl.value = fileReader.result
    })
  })

  return {
    imageFile,
    uploadProcess,
    imageUrl,
    handleImageSelected,
    uploadFile,
    fileName,
    fileSize,
    filesName,
    filesObject,
    deleteFile,
    uploadFileMultiple,
    handleMultipleFilesSelected,
    handlePdfSelected,
    uploadPdfFile,
    handlePiecePdfSelected,
    pdfFile,
    uploadPdfFileEtatDesLieux,
    fileNameEtat,
  }
}
