/* eslint-disable global-require */
import { ref } from '@vue/composition-api'

import axiosClient from '@/helpers/axios'
import useAlertNotification from '@/composables/notification/useAlert'
import useSweetAlertNotification from '@/composables/notification/useSweetAlert'

export default function useProprieteImages() {
  const { successToast } = useAlertNotification()
  const { errorSweetAlert } = useSweetAlertNotification()

  const proprieteImage = ref([])
  const total = ref(null)
  const loader = ref(false)
  const proprieteImageProcess = ref(false)
  const proprieteImageSuccess = ref(false)

  const proprieteImages = ref([])
  const proprieteImagesDataPaginate = ref([])
  const errors = ref('')

  const deleteProprieteImage = async id => {
    try {
      errors.value = ''
      proprieteImageProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.delete(`/delete-propriete-image/${id}`)
      if (response.data.success === true) {
        successToast('Suppression', response.data.message)

        proprieteImageSuccess.value = true
        proprieteImageProcess.value = false
        proprieteImage.value = response.data.data
      }
    } catch (error) {
      proprieteImageProcess.value = false
      proprieteImageSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }
  const storeFilesInPropriete = async data => {
    try {
      errors.value = ''
      proprieteImageProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.post('/store-files-propriete-image', data)
      if (response.data.success === true) {
        successToast('Enregistrement', response.data.message)

        proprieteImageSuccess.value = true
        proprieteImageProcess.value = false
        proprieteImage.value = response.data.data
      }
    } catch (error) {
      proprieteImageProcess.value = false
      proprieteImageSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }

  return {
    proprieteImagesDataPaginate,
    total,
    errors,
    proprieteImage,
    proprieteImages,
    proprieteImageProcess,
    deleteProprieteImage,
    loader,
    proprieteImageSuccess,
    storeFilesInPropriete,
  }
}
